
import { Component, Vue, Watch } from 'vue-property-decorator';
import BeClient from './BeClient.vue';
import BePartner from './BePartner.vue';

@Component({
  components: {
    BeClient,
    BePartner,
  },
})
export default class CommonBecome extends Vue {
  longClasses =
    'tab-nav tab-nav2 clearfix ui-tabs-nav ui-corner-all ui-helper-reset ui-helper-clearfix ui-widget-header justify-content-end';

  becomeClient = 'become-client';

  mounted() {
    setTimeout(() => {
      this.manageTab(true);
    }, 1);
  }

  @Watch('$route.name')
  catchRoute() {
    this.manageTab();
  }

  manageTab(fromMounted = false) {
    const { name } = this.$router.currentRoute;
    const isPartner = name !== this.becomeClient;
    if (!isPartner && fromMounted) {
      return;
    }

    const elementId = isPartner ? 'ui-id-partner' : 'ui-id-client';
    const elem = document.getElementById(elementId);
    if (elem == null) {
      return;
    }

    elem.click();
  }
}
